import React from "react";
import { Link } from "react-router-dom";

class FinanceMenuSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userEmailID: "",
      userRole: "",
      submenuToggle: false,
    };
  }

  componentDidMount() {
    var name = localStorage.getItem("userName");
    var email = localStorage.getItem("userEmail");
    var role = localStorage.getItem("userRole");
    this.setState({
      userName: name,
      userEmailID: email,
      userRole: role,
    });
  }

  handleSubmenuToggle = () => {
    this.setState({
      submenuToggle: !this.state.submenuToggle,
    });
  };

  render() {
    return (
      <section>
        <aside id="leftsidebar" className="sidebar">
          <div className="user-info">
            <div className="info-container">
              <div
                className="name"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {this.state.userName}
              </div>
              <div className="email">{this.state.userEmailID}</div>
            </div>
          </div>
          <div className="menu">
            <ul className="list">
              <li className="header">MAIN NAVIGATION</li>
              <li>
                <Link to="/report-data">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Chef Form List</span>
                </Link>
              </li>
              <li>
                <Link to="/diner-list">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Diner List</span>
                </Link>
              </li>
              <li>
                <Link to="/diner-order-listing">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Order Report</span>
                </Link>
              </li>
			  <li>
                <Link to="/offline-order-listing">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Offline Order Report</span>
                </Link>
              </li>
			  <li>
                <Link to="/party-order-listing">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Party Order Report</span>
                </Link>
              </li>
			  <li>
                <Link to="/tiffin-enquiry-listing">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Tiffin Enquiry Report</span>
                </Link>
              </li>
			  <li>
                <Link to="/chef-chat-count">
                  <i className="material-icons">format_list_numbered</i>
                 <span>Chef Chat/Profile Click Count Report</span>
                </Link>
              </li>
			  <li>
                <Link to="/chef-order-ledger-list">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Chef Ledger Report</span>
                </Link>
              </li>
              <li>
                <Link to="/membership-list">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Membership Listing</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={
                    this.state.submenuToggle
                      ? "menu-toggle waves-effect waves-block toggled"
                      : "menu-toggle waves-effect waves-block"
                  }
                  onClick={this.handleSubmenuToggle}
                >
                  <i className="material-icons">insert_chart</i>
                  <span>Report</span>
                </Link>
                <ul
                  className="ml-menu"
                  style={{
                    display: this.state.submenuToggle ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to="/city-report">City wise report</Link>
                  </li>
                  <li>
                    <Link to="/step-wise-report">Step wise report</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/discount-coupon-code-list">
                  <i className="material-icons">format_list_numbered</i>
                  <span>Manage Discount Coupon Code</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="legal">
            <div className="copyright">
              &copy; <Link to="#">ChefPin</Link>.
            </div>
            <div className="version">
              <b>Version: </b> 1.0.5
            </div>
          </div>
        </aside>
      </section>
    );
  }
}

export default FinanceMenuSection;
